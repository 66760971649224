import React from "react"
import { Link } from "gatsby"

import styles from "./pagination.module.scss"

// migrated from watershed

const Pagination = props => {
  const { currentPage, totalPages, base } = props
  const prevPage = currentPage - 1 <= 1 ? `/${base}` : `/${base}/page/${(currentPage - 1).toString()}/`
  const nextPage =
    currentPage !== undefined && currentPage === totalPages
      ? `/${base}/page/${currentPage.toString()}/`
      : `/${base}/page/${(currentPage + 1).toString()}/`

  const displayItem = (i, array) => {
    array.push(
      <li key={`pagination-${base}-${i}`} className={currentPage === i + 1 ? styles.current : ""}>
        <Link
          key={`pagination-number${i + 1}`}
          to={i === 0 ? `/${base}/` : `/${base}/page/${i + 1}/`}
          className="link-pink"
        >
          <span className={styles.visuallyHidden}>Page</span>
          {i + 1}
        </Link>
      </li>
    )
  }

  const paginate = () => {
    let html = []
    if (totalPages <= 6) {
      Array.from({ length: totalPages }, (_, i) => displayItem(i, html))
    } else if (currentPage < 5) {
      Array.from({ length: 5 }, (_, i) => displayItem(i, html))
      html.push(<li>...</li>)
      displayItem(totalPages - 1, html)
    } else if (currentPage > 4 && currentPage < totalPages - 3) {
      displayItem(0, html)
      html.push(<li>...</li>)
      for (let i = currentPage - 3; i < currentPage + 2; i++) {
        displayItem(i, html)
      }
      html.push(<li>...</li>)
      displayItem(totalPages - 1, html)
    } else {
      displayItem(0, html)
      html.push(<li>...</li>)
      for (let i = totalPages - 5; i < totalPages; i++) {
        displayItem(i, html)
      }
    }
    return html
  }

  return (
    <nav aria-label="pagination" className={styles.pagination}>
      <div className={styles.paginationInner}>
        <ul>
          <li className={currentPage === 1 ? styles.current : ""}>
            <Link
              to={prevPage}
              aria-disabled={currentPage === 1}
              className={currentPage === 1 ? styles.disabled : "link-pink"}
            >
              <span>Previous</span>
            </Link>
          </li>
          {paginate()}
          <li className={currentPage === totalPages ? styles.current : ""}>
            <Link
              to={nextPage}
              aria-disabled={currentPage === totalPages}
              className={currentPage === totalPages ? styles.disabled : "link-pink"}
            >
              <span>Next</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Pagination
