import React from "react"

import MainWrapper from "../wrapper/wrapper"

import styles from "./layout2col.module.scss"

/* Example usage

 import * as Layout2Col from "../components/layout/layout2col/layout2col"

 <Layout2Col.Wrapper>
   <Layout2Col.Main>
     main content
   </Layout2Col.Main>
   <Layout2Col.Sidebar}>
     sidebar content
   </Layout2Col.Sidebar>
 </Layout2Col.Wrapper>

 */

export const Wrapper = ({ children }) => (
  <MainWrapper noPadding>
    <div className={styles.inner}>{children}</div>
  </MainWrapper>
)
export const Sidebar = ({ children }) => <aside className={styles.sidebar}>{children}</aside>
export const Main = ({ children }) => <div className={styles.post}>{children}</div>

// used for blog post where content order has to be changed for SEO
export const MobileSidebar = ({ children }) => <aside className={styles.mobileSidebar}>{children}</aside>
