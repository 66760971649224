import React from "react"
import { Link } from "gatsby"

import Wrapper from "../layout/wrapper/wrapper"

import styles from "./breadcrumbs.module.scss"

const Breadcrumbs = ({ steps }) => (
  <Wrapper noPadding>
    <ul className={styles.breadcrumbs}>
      <li className="visually-hidden">
        <Link to="/">Home</Link>
      </li>
      {steps.map(step => (
        <li key={step.link}>
          <Link to={`/${step.link}`} activeStyle={{ fontWeight: 600 }}>
            {step.title}
          </Link>
        </li>
      ))}
    </ul>
  </Wrapper>
)

export default Breadcrumbs
