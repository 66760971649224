import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./animatedText.module.scss"

const AnimatedText = ({ text, blocks }) => {
  const getDelay = index => (index < 10 ? `0.${index}s` : `1.${index.toString().split("").pop()}s`)

  let blocksText = []

  if (blocks) {
    blocks.forEach(item => {
      item.text.split(" ").forEach(text => {
        text.length &&
          blocksText.push({
            text,
            marks: item.marks,
          })
      })
    })
  }

  return (
    <>
      {blocksText.length ? (
        <>
          {blocksText.map((word, index) => (
            <React.Fragment key={`${word}-${index}`}>
              <span className={styles.wrapper}>
                <span
                  className={cx(styles.word, word.marks[0] ? "highlight-pink" : "")}
                  style={{ animationDelay: `${getDelay(index)}` }}
                >{`${word.text} `}</span>
              </span>{" "}
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          {text?.split(" ").map((word, index) => (
            <React.Fragment key={`${word}-${index}`}>
              <span className={styles.wrapper}>
                <span
                  className={cx(styles.word, " ")}
                  style={{ animationDelay: `${getDelay(index)}` }}
                >{`${word} `}</span>
              </span>{" "}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  )
}

export default AnimatedText

AnimatedText.propTypes = {
  text: PropTypes.string,
  blocks: PropTypes.array,
}
