import React from "react"
import { Link } from "gatsby"
import cx from "classnames"

import styles from "./categories.module.scss"
import { getTotal } from "../../../helpers/categories"

// Used for blog, events and resources

const Categories = ({ hideOnMobile, hideOnDesktop, categories, totals, allLink, name }) => {
  //todo
  const hideClass = hideOnMobile ? styles.hideOnMobile : hideOnDesktop ? hideOnDesktop : null

  return (
    <div className={cx(styles.categories, hideClass)}>
      <h3 className={styles.title}>Filter {name}</h3>
      <ul>
        <li>
          <Link to={allLink}>All</Link>
        </li>
        {categories.nodes.map((cat, id) => (
          <li key={id}>
            <Link to={`${allLink}/${cat.slug.current}`}>
              {cat.title} ({getTotal(cat.id, totals)})
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Categories
