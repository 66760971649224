import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import cx from "classnames"

import { accessibleContainerAsLink } from "../../../helpers/accessibility"

import styles from "./listItem.module.scss"

// Used for blog, events and resources

const ListItem = ({ featuredImage, title, excerpt, category, slug, base, dateDescription }) => {
  const link = `/${base}/${category.slug.current}/${slug.current}`
  const containerAsLinkProps = accessibleContainerAsLink(link)

  return (
    <article
      className={cx("girdItem small-12 medium-6 large-4", styles.wrapper)}
      role="link"
      tabIndex="0"
      {...containerAsLinkProps}
    >
      <Link to={link}>
        <Img
          className={styles.image}
          fluid={featuredImage?.asset.fluid}
          alt={`${title} Read more`}
          imgStyle={{
            objectFit: "contain",
          }}
        />
      </Link>
      <header>
        <p className={styles.category}>{category.title}</p>
        <h3 className={styles.title}>{title}</h3>
      </header>
      <h5>{dateDescription}</h5>
      <p>{excerpt}</p>
      <Link to={link} className="btn-tertiary">
        <span className="visually-hidden">{title} </span>Read more
      </Link>
    </article>
  )
}
export default ListItem
