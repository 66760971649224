import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Categories from "../../shared/categories/categories"

const EventCategories = ({ hideOnMobile, hideOnDesktop }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityEventCategory {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityEvent(filter: { tabs: { content: { type: { eq: "upcoming" } } } }) {
        group(field: tabs___content___category___id) {
          totalCount
          fieldValue
        }
      }
    }
  `)

  const categories = data.allSanityEventCategory
  const totals = data.allSanityEvent.group

  return (
    <Categories
      categories={categories}
      totals={totals}
      hideOnMobile={hideOnMobile}
      hideOnDesktop={hideOnDesktop}
      allLink="/events"
      name="events"
    />
  )
}

export default EventCategories
