import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import AnimatedText from "../animatedText/animatedText"

import styles from "./heroSimple.module.scss"

// Default Hero used at most of the pages except for CS and Homepage

const Hero = ({ title, blocks, subtitle, small = false }) => (
  <section className={styles.wrapper}>
    <h1 className={cx(styles.title, small ? styles.small : "")}>
      <AnimatedText text={title} blocks={blocks} />
    </h1>
    {subtitle ? <p>{subtitle}</p> : null}
  </section>
)

export default Hero

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  blocks: PropTypes.array,
}
