import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/layout"
import * as Layout2Col from "../components/layout/layout2col/layout2col"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import BlockContent from "../components/blockContent/blockContent"
import Seo from "../components/seo/seo"

import EventCategories from "../components/event/eventCategories/eventCategories"
import ListItem from "../components/shared/listItem/listItem"
import Pagination from "../components/shared/pagination/pagination"

// todo
// events past is the same with different data and different link for the all
import styles from "./shared.module.scss"

const Event = ({ data, pageContext }) => {
  const { content, seo } = data.sanityEventListing.tabs
  let events = data.allSanityEvent.nodes
  const { currentPage, totalPages, type } = pageContext

  const steps = [
    {
      link: `events`,
      title: "Events",
    },
  ]

  return (
    <Layout>
      <Seo
        isBlogPost={false}
        title={content.title}
        path={pageContext.path}
        image={content.featuredImage?.asset.url}
        breadcrumbs={steps}
        {...seo}
      />
      <Breadcrumbs steps={steps} />
      <HeroSimple blocks={content.hero[0].children} subtitle={content.subtitle} />
      <Layout2Col.Wrapper>
        <Layout2Col.Main>
          {events.length ? (
            <>
              <div className="grid">
                {events.map(post => (
                  <ListItem {...post.tabs.content} base="events" />
                ))}
              </div>
              {totalPages > 1 && (
                <Pagination
                  base={type === "previous" ? "events/past" : "events"}
                  currentPage={currentPage}
                  totalPages={totalPages}
                />
              )}
            </>
          ) : (
            <div className={styles.noContent}>
              <BlockContent blocks={content.text} />
            </div>
          )}
        </Layout2Col.Main>
        <Layout2Col.Sidebar>
          <EventCategories />
          <Link to="/events/past" className="link-pink">
            View past events
          </Link>
        </Layout2Col.Sidebar>
      </Layout2Col.Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!, $type: String) {
    sanityEventListing {
      tabs {
        content {
          hero: _rawHero
          subtitle
          text: _rawText
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityEvent(
      filter: { tabs: { content: { type: { eq: $type } } } }
      sort: { fields: tabs___content___eventDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        tabs {
          content {
            title
            subtitle
            excerpt
            type
            dateDescription
            eventDate
            category {
              title
              slug {
                current
              }
            }
            slug {
              current
            }
            featuredImage {
              asset {
                fluid(maxWidth: 580) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Event
